import { Suspense } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { ApolloProvider } from '@apollo/client'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateAdapter from '@date-io/date-fns'
import { BrowserRouter as Router } from 'react-router-dom'
import { store, StoreContext } from 'hooks/useStore'
import { AuthProvider } from 'hooks/useAuth'
import Routes from './components/Routes'
import SnackbarProvider from './components/SnackbarProvider'
import apolloClient from './apolloClient'
import theme from './theme'
import './i18n'
import './styles.css'

const App = (): JSX.Element => (
  <Suspense fallback="loading">
    <StoreContext.Provider value={store}>
      <ApolloProvider client={apolloClient}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <Router>
                <AuthProvider>
                  <LocalizationProvider dateAdapter={DateAdapter}>
                    <Routes />
                  </LocalizationProvider>
                </AuthProvider>
              </Router>
            </SnackbarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </ApolloProvider>
    </StoreContext.Provider>
  </Suspense>
)

export default App
