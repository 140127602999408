import {
  useEffect,
  useContext,
  createContext, useState, useCallback,
} from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import cookie from 'js-cookie'
import decode from 'jwt-decode'
import request from 'utils/request'
import useMe, { UserProfile } from 'apolloHooks/query/useMe'
import { wsClient } from 'core/apolloClient'

interface Props {
  children: React.ReactElement,
}

interface DecodedToken {
  exp: number,
  iat: number,
  sub: string,
  id: string,
  ema: string,
  name: string,
  eid?: string,
}

interface User extends UserProfile {
  exhibitorId: string | undefined,
}

interface Context {
  auth: (values) => void,
  user: User,
  refetchMe: () => void,
  logout: (fromAgreement?: boolean) => void,
}

const AuthContext = createContext<Context>({
  auth: () => {},
  logout: () => {},
  refetchMe: () => {},
  user: {} as User,
})

const LOGIN = `
  query($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`

const EXHIBITOR_LOGIN = `
  query($email: String!, $password: String!) {
    exhibitorLogin(email: $email, password: $password) {
      accessToken
      refreshToken
    }
  }
`

const EXHIBITION_LOGIN = `
  query($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      accessToken
      refreshToken
    }
  }
`

const REFRESH_TOKEN = `
  query($token: String!) {
    refreshToken(token: $token) {
      accessToken
      refreshToken
    }
  }
`

const loginQuery = {
  attendee: LOGIN,
  exhibitor: EXHIBITOR_LOGIN,
  exhibition: EXHIBITION_LOGIN,
}

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const history = useHistory()
  const location = useLocation()
  const [getMe, { data: user, client, refetch: refetchUserProfile }] = useMe()
  const publicRoutes = ['/sorry', '/login', '/register']
  const [landingPath, setLandingPath] = useState('/')

  useEffect(() => {
    loadUserFromCookies(true)
  }, [])

  const logout = (fromAgreement = false) => {
    const { Authorization, ...rest } = request.defaults.headers.common

    request.defaults.headers.common = rest
    cookie.remove('accessToken')
    cookie.remove('refreshToken')
    client?.clearStore()
    window.localStorage.clear()

    if (!publicRoutes.includes(location.pathname)) {
      setLandingPath(location.pathname)
      history.push(fromAgreement ? '/sorry' : '/login')
    }
  }

  const setTokens = (response: { accessToken: string, refreshToken: string }) => {
    const { accessToken, refreshToken } = response

    cookie.set('accessToken', accessToken, { expires: new Date(decode<DecodedToken>(accessToken).exp * 1000) })
    cookie.set('refreshToken', refreshToken, { expires: new Date(decode<DecodedToken>(refreshToken).exp * 1000) })
    request.defaults.headers.common.Authorization = `Bearer ${accessToken}`

    getMe()
    setTimeout(refreshUserFromCookies, 60000 * 9)
  }

  const auth = useCallback(async values => {
    const { data: { data, errors } } = await request.post(
      process.env.REACT_APP_BUILD_TYPE === 'exhibition' ? process.env.REACT_APP_EXHIBITION_ADMIN_URL as string : '',
      {
        query: loginQuery[process.env.REACT_APP_BUILD_TYPE as string],
        variables: values,
      },
    )

    if (errors) {
      return { general: errors[0].message }
    }
    setTokens(data.login || data.exhibitorLogin)
    wsClient.restart()
    history.push(landingPath)
  }, [landingPath])

  const refetchMe = () => {
    if (refetchUserProfile) {
      refetchUserProfile()
    }
  }

  const loadUserFromCookies = async (redirect: boolean) => {
    try {
      const refreshToken = cookie.get('refreshToken')
      const accessToken = cookie.get('accessToken')

      if ((accessToken && ((decode<DecodedToken>(accessToken).exp * 1000 - new Date().getTime()) < 60000))
        || (!accessToken && refreshToken)
      ) {
        const { data: { data } } = await request.post(
          process.env.REACT_APP_BUILD_TYPE === 'exhibition' ? process.env.REACT_APP_EXHIBITION_ADMIN_URL as string : '',
          { query: REFRESH_TOKEN, variables: { token: refreshToken } },
        )

        if (redirect && location.pathname === '/login') {
          history.push('/')
        }
        setTokens(data.refreshToken)
      } else if (accessToken) {
        const decodedToken = decode<DecodedToken>(accessToken)

        if (redirect && location.pathname === '/login') {
          history.push('/')
        }

        request.defaults.headers.common.Authorization = `Bearer ${accessToken}`

        getMe()
        setTimeout(refreshUserFromCookies, new Date(decodedToken.exp * 1000).getTime() - new Date().getTime() - 60000)
      } else if (!accessToken && !refreshToken) {
        logout()
      }
    } catch (error) {
      logout()
    }
  }

  const refreshUserFromCookies = async () => {
    await loadUserFromCookies(false)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        user: user && cookie.get('accessToken')
          ? { ...user.me, exhibitorId: decode<DecodedToken>(cookie.get('accessToken')).eid }
          : {} as User,
        logout,
        refetchMe,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext)

export default useAuth
