import { createTheme } from '@mui/material/styles'

const computedBody = getComputedStyle(document.body)
const primaryMainDark = computedBody.getPropertyValue('--primary-color-dark').trim()
const secondaryMain = computedBody.getPropertyValue('--text-foreground-color').trim()
const contrastText = computedBody.getPropertyValue('--appbar-text-color').trim()
const inputBackground = computedBody.getPropertyValue('--input-background').trim()
const backgroundColor = computedBody.getPropertyValue('--background-color').trim()
const gray = computedBody.getPropertyValue('--gray-600').trim()
const textDark = computedBody.getPropertyValue('--text-dark-foreground-color').trim()

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false;
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true;
    desktop: true;
  }

  interface Palette {
    gray: Palette['primary'],
  }
  interface PaletteOptions {
    gray: PaletteOptions['primary'];
  }
}

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    gray: true;
  }
}

export default createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      desktop: 1024,
    },
  },
  typography: {
    fontFamily: 'Roboto',
  },
  palette: {
    primary: { main: primaryMainDark, contrastText },
    secondary: { main: secondaryMain },
    gray: { main: gray },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          left: 12,
        },
        filled: {
          left: 0,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          paddingLeft: 12,
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          paddingLeft: 0,
          backgroundColor: inputBackground,
          '&:hover': {
            backgroundColor: inputBackground,
          },
          '&$focused': {
            backgroundColor: inputBackground,
          },
        },
        input: {
          paddingLeft: 12,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: '6px 16px',
          color: '#4d4d4d',
          '&.Mui-selected': {
            color: textDark,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paperScrollPaper: {
          maxHeight: 'calc(100% - 128px)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
        sizeLarge: {
          fontSize: '18px',
          fontWeight: 'bold',
          lineHeight: '100%',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: 16,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: '20px',
          fontWeight: 'bold',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
      },
    },
  },
})
