import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
  ApolloLink,
  Operation,
  FetchResult,
  Observable,
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import cookie from 'js-cookie'
import { print } from 'graphql'
import { createClient, Client, ClientOptions } from 'graphql-ws'

class GraphQLWsLink extends ApolloLink {
  constructor(private client: Client) {
    super()
  }

  public request(operation: Operation): Observable<FetchResult> {
    return new Observable(sink => this.client.subscribe<FetchResult>(
      { ...operation, query: print(operation.query) },
      {
        next: sink.next.bind(sink),
        complete: sink.complete.bind(sink),
        error: sink.error.bind(sink),
      },
    ))
  }
}

interface RestartableClient extends Client {
  restart(): void;
}

const createRestartableClient = (options: ClientOptions): RestartableClient => {
  let restartRequested = false
  let restart = () => {
    restartRequested = true
  }

  const client = createClient({
    ...options,
    on: {
      ...options.on,
      opened: (socket: any) => {
        options.on?.opened?.(socket)

        restart = () => {
          if (socket.readyState === WebSocket.OPEN) {
            socket.close(4205, 'Client Restart')
          } else {
            restartRequested = true
          }
        }

        if (restartRequested) {
          restartRequested = false
          restart()
        }
      },
    },
  })

  return {
    ...client,
    restart: () => restart(),
  }
}

const exhibitionLink = createHttpLink({ uri: process.env.REACT_APP_EXHIBITION_QUERY_URL })
const exhibitionAdminLink = createHttpLink({ uri: process.env.REACT_APP_EXHIBITION_ADMIN_URL })
const generalLink = createHttpLink({ uri: process.env.REACT_APP_API_URL })

const exhibitionLinks = split(
  operataion => {
    const context = operataion.getContext()

    return context.isAdmin
  },
  exhibitionAdminLink,
  exhibitionLink,
)

const httpLink = split(
  () => process.env.REACT_APP_BUILD_TYPE === 'exhibition',
  exhibitionLinks,
  generalLink,
)

const getToken = () => {
  const token = cookie.get('accessToken')

  return token ? `Bearer ${token}` : ''
}

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    Authorization: getToken(),
  },
}))

export const wsClient = createRestartableClient({
  url: process.env.REACT_APP_API_WS_URL as string,
  shouldRetry: () => true,
  lazy: true,
  connectionParams: () => {
    const token = getToken()

    if (!token) return {}

    return {
      Authorization: token,
    }
  },
})

const wsLink = new GraphQLWsLink(wsClient)

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    )
  },
  wsLink,
  authLink.concat(httpLink),
)

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        findUserProfiles: {
          keyArgs: false,
          merge(existing, incoming, { args }) {
            const merged = existing ? existing.slice(0) : []
            // eslint-disable-next-line
            for (let i = 0; i < incoming.length; ++i) {
              merged[args?.page - 1 + i] = incoming[i]
            }

            return merged
          },
        },
      },
    },
    Exhibitor: {
      fields: {
        files: {
          merge(existing, incoming) {
            return incoming
          },
        },
      },
    },
  },
})

export default new ApolloClient({
  link: splitLink,
  cache,
})
