import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

const useBreakPoint = (breakPoint: 'mobile' | 'desktop', condition: 'up' | 'down' | 'only' = 'only'): boolean => {
  const theme = useTheme()
  const isTargetBreakPoint = useMediaQuery(theme.breakpoints[condition](breakPoint))

  return isTargetBreakPoint
}

export default useBreakPoint
