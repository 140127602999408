import { makeAutoObservable } from 'mobx'

class RequestMeeting {
  currentStep = 0

  lastStep = 0

  requestMeetingVisible = false

  constructor() {
    makeAutoObservable(this)
  }

  setCurrentStep(step: number): void {
    this.currentStep = step
  }

  setLastStep(step: number): void {
    this.lastStep = step
  }

  setRequestMeetingVisible(visible: boolean): void {
    this.requestMeetingVisible = visible
  }
}

export default RequestMeeting
