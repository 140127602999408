import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'de',
    supportedLngs: ['en', 'de'],
    debug: true,
  })
  .then(
    () => {
      i18n.addResourceBundle('de', 'app', { name: process.env.REACT_APP_NAME_DE }, false, false)
      i18n.addResourceBundle('en', 'app', { name: process.env.REACT_APP_NAME_EN }, false, false)
    },
  )

export default i18n
