import { useState } from 'react'
import { SnackbarProvider } from 'notistack'
import useBreakPoint from 'hooks/useBreakPoint'
import './styles.css'

interface Props {
  children: JSX.Element,
}

const CustomSnackbarProvider = ({ children }: Props): JSX.Element => {
  const isMobileView = useBreakPoint('mobile')
  // eslint-disable-next-line
  const [audioRing] = useState(new Audio(require('assets/audios/messageNotification.mp3').default))

  return (
    <SnackbarProvider
      maxSnack={isMobileView ? 1 : 3}
      autoHideDuration={isMobileView ? 5000 : 10_000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      onEnter={() => audioRing.play()}
    >
      {children}
    </SnackbarProvider>
  )
}

export default CustomSnackbarProvider
