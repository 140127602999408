import { useLazyQuery, gql } from '@apollo/client'

type UserType = 'ATTENDEE' | 'EXHIBITOR'

interface UserPrivacy {
  showEmail: string,
  showPhone: string
}

export interface UserProfile {
  id: string,
  name: string,
  email: string,
  agreement: boolean,
  type: UserType,
  company: string,
  phone: string,
  avatar: string,
  images: string[],
  profile: string,
  privacy: UserPrivacy,
  tutorialState: string,
}

interface UserProfileData {
  me: UserProfile,
}

export const USER_PROFILE_FRAGMENT = gql`
  fragment UserProfileFields on UserProfile {
    id
    name
    email
    agreement
    type
    company
    phone
    avatar
    images
    profile
    privacy {
      showEmail
      showPhone
    }
    tutorialState
  }
`

const USER_PROFILE = gql`
  ${USER_PROFILE_FRAGMENT}
  query GetMe {
    me {
      ...UserProfileFields
    }
  }
`

export default () => useLazyQuery<UserProfileData>(USER_PROFILE)
