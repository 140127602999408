import { makeAutoObservable } from 'mobx'
import { Appointment } from 'apolloHooks/query/useAppointments'

class AppointmentStore {
  userId: string

  list: Appointment[]

  selected: Appointment | null | undefined

  hasUpdates: boolean

  constructor() {
    makeAutoObservable(this)
    this.userId = ''
    this.list = []
    this.selected = null
    this.hasUpdates = false
  }

  setList(list: Appointment[]): void {
    this.list = list

    this.updateUnread()
  }

  setUserId(userId: string): void {
    this.userId = userId
  }

  setSelected(request: Appointment | null | undefined): void {
    this.selected = request
  }

  remove(appointment: Appointment): void {
    if (this.selected?.ID === appointment.ID) {
      this.setSelected(null)
    }

    const list = Array.from(this.list)
    let found = -1
    list.forEach((value, index) => {
      if (value.ID === appointment.ID) {
        found = index
      }
    })
    if (found >= 0) {
      list.splice(found, 1)
    }
    this.list = list
    this.updateUnread()
  }

  update(appointment: Appointment): void {
    if (this.selected?.ID === appointment.ID) {
      this.setSelected(appointment)
    }
    const list = Array.from(this.list)
    let updated = false
    list.forEach((value, index) => {
      if (value.ID === appointment.ID) {
        list[index] = appointment
        updated = true
      }
    })
    if (!updated) {
      list.push(appointment)
    }
    this.list = list
    this.updateUnread()
  }

  updateUnread(): void {
    let unread = false
    this.list.forEach(value => {
      value.messages.forEach(msg => {
        let read = false
        msg.readMarkers.forEach(marker => {
          if (marker.userId === this.userId) {
            read = true
          }
        })
        if (!read) {
          unread = true
        }
      })
    })
    this.hasUpdates = unread
  }
}

export default AppointmentStore
